.entity-config-form {
  .settingsRowable {
    .settingsRowable_descriptionWrapper {
      p {
        margin: 0px;
      }
    }

    .settingsRowable_buttons {
      align-self: center;
    }
  }

  &:first-of-type {
    .card {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    .card {
      margin-bottom: 0px;
    }
  }
}