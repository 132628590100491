input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #FCF1DC inset;
  border-color: #FCF1DC;

  /* Change the color to your own background color */
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #FCF1DC inset;
  /*your box-shadow*/
  border-color: #FCF1DC;

}


form {
  label {
    padding: 0.55rem 0.75rem 0.35rem 0.75rem;
    margin-bottom: 1px;
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 10px !important;

    &:last-child {
      float: right;
    }

    a {
      font-weight: 600 !important;
      color: #FAC85A !important;
    }
  }

  .form-control {
    height: auto;
  }

  input {
    margin-top: 0px !important;
    border-radius: 4px !important;
    color: black !important;
    margin-bottom: 10px !important;
    padding: 1rem 1.5rem !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px;
  }

  button {
    margin-top: 35px !important;
  }
}

.nav-tabs {
  .nav-item.nav-link {
    color: #525f7f;
  }

  .nav-item.nav-link.active {
    color: #FAC85A;
  }
}

.table {
  tr {

    td,
    th {
      text-align: center;

      &:first-of-type {
        text-align: left;
      }
    }
  }

  tbody {
    background: #fafafa;

    tr {
      transition-duration: 0.3s;

      &:hover {
        background: #fcfcfc;
        transition-duration: 0s;

      }
    }

    td {
      cursor: pointer;

      &:first-of-type {
        cursor: auto;
        background: white;

        &:hover {
          box-shadow: none;
          transition-duration: 0s;
        }
      }

      transition-duration: 1s;

      &:hover {
        background: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
        transition-duration: 0s;
      }
    }
  }
}

.circleAddButton_with_dropdown {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;

  .dropdown-toggle {
    margin: auto;
  }

  .dropdown-menu {
    transform: translate3d(-50%, calc(-100% + 10px), 0px) !important;
    margin-left: 50%;
    text-align: center;
    min-width: 120px;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    box-shadow: 0 10px 100px rgba(50, 50, 93, 0.05), 0 15px 35px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05) !important;

    .dropdown-item.active,
    .dropdown-item:active {
      color: black;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  button {
    // border-radius: 100px;
    padding: 7px 12px;

    i {
      transform: translateY(1px);
      color: #FAC85A;
    }
  }
}


.card-header {
  padding: 0.7rem 1.8rem 0.6rem 1.8rem;
  background: linear-gradient(90deg, white 0%, white 100%) !important;
  // box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03);
  border-top: 3px solid #FAC85A;
  z-index: 1;
  // min-height: 72px;
  line-height: 48px;

  // display: flex;
  // align-items: center;
  .col {
    align-self: center;
  }

  .form-group {
    margin-bottom: 0px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-top-right-radius: 25px;
    border-top-left-radius: 5px !important;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 5px !important;

    .form-control,
    .input-group-text {
      background-color: #ffffff00;
      box-shadow: 0 0px 0px rgba(50, 50, 93, 0), 0 0px 0 rgba(0, 0, 0, 0);
      transition: box-shadow .15s ease;
      font-weight: 400;
      color: black !important;
      margin-bottom: 0px !important;
      border: none;
    }

    .form-control {
      padding-left: 5px !important;
      border-left: 0px;
    }

    .input-group-alternative {
      box-shadow: 0 0px 0px rgba(50, 50, 93, 0), 0 0px 0 rgba(0, 0, 0, 0);
      font-weight: 600;
      color: black;
      border-bottom-left-radius: 3px;
    }

    &:hover {
      .input-group-alternative {
        box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
      }
    }

    &:focus-within {
      .input-group-alternative {
        box-shadow: 0 1px 5px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
      }

      .form-control,
      .input-group-text {
        background-color: #ffffff10;
        color: black !important;
      }
    }
  }
}

.card {
  margin-top: 15px;
  margin-bottom: 15px;
  background: white; //#fbfbfb;
  border: 1px solid rgba(0, 0, 0, 0.08);
  // overflow: hidden;
  border-top: 0px;
  overflow: visible;
}

.form-control {
  color: black;
}

.form-label {
  padding-top: 10px;
}

.form-control-alternative {
  border-radius: 4px;
}

.deleteItemWrapper {
  i {
    margin-right: -5px;
    padding: 15px;
    line-height: 15px;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    }

    &:active {
      box-shadow: 0 1px 5px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
      background: #fafafa;
    }
  }
}

.inputs-group-main {
  margin-top: 3rem !important;
}

.card {
  border-radius: 4px;
  border: white;

}

.card-body {
  background: white; //#fbfbfb;
  overflow: visible;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 4px;

  .card-body {
    border-radius: 4px;
  }

  .dropzone {
    min-height: 210px;
  }

  h5 {
    margin-top: 2rem;
    text-align: center;
    font-size: 2.1rem;
    font-weight: 400;
    color: #FAC85A;
  }
}