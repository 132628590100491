.rules-page {
  height: auto;
  width: 100%;
  padding-bottom: 100px;
  
  .main-section{
    padding-top: 150px;
  }

  h3 {
    margin-right: auto;
    padding-bottom: 1.5%;
    padding-top: 100px;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .policy {
    padding-bottom: 1%;
    padding-top: 1%;
    font-size: 1.6rem;
    width: 100%;

    p {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}