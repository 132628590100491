.sign-upin-form {
  padding-bottom: 0px;
  
  .notificationsBlock {
    margin-bottom: 0rem;
    margin-top: 4rem;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    width: 100%;

    p {
      width: 100%;
    }

    .toggle-wrapper {
      transform: translateY(-22px) translateX(3px);
    }
  }

  input {
    margin-top: 15px;
    margin-bottom: 15px;

  }

  a {
    color: black;
    font-weight: 500;
  }

  p {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
  }

  .btnWrapper {
    margin-bottom: 25px;

    button {
      display: block;
      border-radius: 4px;
      width: 100%;
      position: relative;
      box-shadow: none;

      .spinner-border {
        display: inline-block;
        position: absolute;
        right: 12px;
        width: 2.5rem;
        height: 2.5rem;
        vertical-align: text-bottom;
        border: 1.3px solid #FAC85A;
        border-right: 1.3px solid transparent;
        border-radius: 50%;
        animation: spinner-border .75s linear infinite;
      }

      @keyframes spinner-border {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}