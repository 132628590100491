.variant-item {
  border-top: 1.5px solid #FAC85A;
  margin-top: 55px;
  padding-bottom: 20px !important;

  .variant-item-infoBar {
    transform: translateY(-14px);

    span {
      background: white;
      padding-right: 15px;
      padding-left: 7px;
      font-weight: 600;
      font-size: 1.2rem;
    }

    i {
      background: white;
      padding: 7px 8px 6px 15px;
      float: right;
      transform: translateY(-1px);
      font-size: 1.5rem;
      cursor: pointer;
      // color: #f04c4c;
    }
  }

  form{
    padding-left: 25px;
  }
  .form-label {
    // &:nth-of-type(2) {
    padding-top: 0px;
    margin-top: 0px !important;
    // }
  }

  &:first-of-type {
    // border-top: none;
    // margin-top: 0px;
  }
}