.deleteImage {
  float: right;
  transform: translateY(170%);
  margin-right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #f04c4c;
}

.dropzone-wrapper {
  background-position: center center;
  background-origin: content-box;
  background-size: contain;
  background-repeat: no-repeat;
  height: calc(100% - 31px);
  border-radius: 4px;
  border: 1px solid #cad1d7;
  padding: 0.325rem;
  background-color: white;
  position: relative;
  overflow: hidden;
  .deleteImage {
    transition-duration: 0.3s;
    position: absolute;
    top: 10px;
    right: -15px;
    transform: none;
    opacity: 0;
  }

  &:hover{
    .deleteImage {
      right: -5px;
      opacity: 1;
    }
  }
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 0.65px #ccc;
    width: 100%;
    height: 100%;
    min-height: 150px;
    border-radius: 3px;


    &:after {
      content: "";
      display: block;
    }

    .placeholder {
      pointer-events: none;
      opacity: 0.3;
    }

    svg {
      pointer-events: none;
    }

    .fileInput {
      display: none;
    }
  }

  .highlight {
    background-color: whitesmoke;
    animation: moveDash 0.25s;
    border: dashed 5px #ccc;
    opacity: 0.5;

    &:after {
      transition-duration: 0.25s;
      content: "";
      display: block;
    }
  }

  @keyframes moveDash {
    from {
      border: dashed 0.65px #ccc;
    }

    to {
      border: dashed 5.1px #ccc;
    }
  }
}