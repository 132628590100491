.domains-config-form {

  .inputWrapper {
    display: flex;

    input {
      padding-right: 4rem;
    }

    button {
      margin-top: 1px !important;
      margin-bottom: 11px !important;
      margin-left: -1px;
      border-radius: 100px;
      padding: 0.25rem 1rem;
      transform: translateX(-100%);
      box-shadow: none;
    }
  }

  .customBedgeWrapper {
    display: flex;
    flex-wrap: wrap;

    .customBedge {
      margin: 0px 15px 0px 0px;
      background: lighten(#FAC85A, 20%);
      border-radius: 100px;
      padding-left: 18px;

      a {
        color: black;
        height: 100%;
        padding: 4px 4px 4px 4px;

        &:hover {
          color: #666;
        }
      }

      .fa {
        font-weight: 300;
        margin-left: 10px;
        cursor: pointer;
        padding: 13px 16px;
        background: white;
        border-radius: 100px;
        border: 1px solid #eee;

        &:hover {
          background: #fafafa;
        }

        &:active {
          background: #eee;
        }
      }
    }
  }

  &:first-of-type {
    .card {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    .card {
      margin-bottom: 0px;
    }
  }
}