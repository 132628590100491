.embed-form {
  margin-bottom: 40px;
  .card {
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fbfbfb;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    border-top: 0px;
  }

  .form-control-alternative {
    border-radius: 4px;
  }

  .form-label {
    padding-top: 10px;
  }
}