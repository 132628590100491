.input-item {

  &:first-of-type {
    .card {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    .card {
      // margin-bottom: 0px;
    }
  }
}