.globalAlert {
  left: 0px;
  bottom: 0px;
  width: 250px;
  padding: 20px 10px 0px;
  height: auto;
  position: fixed;
  background: #EA6F59;
  color: white;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  z-index: 999;

  h4 {
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
  }

  p {
    font-size: 1.2rem;
  }
}