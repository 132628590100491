.home-panel {
  padding-bottom: 30px;
  animation: loadDelay 0.3s normal forwards ease-in-out;

  .delay-loading {
    opacity: 0;
    animation: loadDelay 0.3s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 0.1s;
    background: white;
    border-radius: 4px;
  }

  @keyframes loadDelay {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .jumbotron {
    padding-left: 22px;
    margin-top: 10px;

    h2 {
      font-weight: 700;
    }

    padding-bottom: 10px;
  }

  .chartCard {
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;

    .card-body {
      .chartWrapper {
        width: 100%;
        text-align: center;
        padding-top: 8px;
      }
    }
  }

  .fastNav {
    display: flex;
    align-items: center;

    a {
      margin-right: 15px;
    }

    .supportBtn {
      color: #FAC85A;
      margin-left: auto;
      font-weight: 600;
      margin-right: 0px;

      &:hover {
        color: darken(#FAC85A, 10);
      }
    }
  }

  .fastFooter {
    p {
      margin-bottom: 0px;
    }
  }

}

.renameHomeScreen {
  margin-top: 30px;

  form {
    margin: auto;
    width: 100%;

    input {
      padding: 1.2rem 1.2rem !important;
    }

    button {
      height: 100%;
      float: left;
      width: 100%;
      margin-top: 0px !important;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .col-xl-8 {
    padding-bottom: 40px;
    padding-top: 20px;
  }

  .colJumbo {
    .card {
      padding: 3rem;
    }
  }

  .col,
  .col-xl-8,
  .col-xl-6,
  .col-xl-12 {
    align-self: center;
    text-align: center;

    img {
      margin-top: 50px;
      margin-bottom: 50px;
      max-width: 100%;
      padding-right: 10px;
      width: 100%;
    }
  }
}