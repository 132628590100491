.kalibracja-panel {
  .delay-loading {
    opacity: 0;
    animation: loadDelay 0.3s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 0.3s;
  }

  .container {
    // max-width: 1300px;
  }

  @keyframes loadDelay {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  thead {
    tr {
      width: 100%;
      max-height: 57px;

      img {
        max-height: calc(57px - 2rem);
      }
    }
  }

  .table {
    .wagesRow {
      td {
        &:first-of-type {
          padding: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 250px;
        }

        padding: 0px;

        .control-info-panel {
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 3rem;
            color: lighten(black, 85);
            cursor: pointer;
            padding: 2px;
            width: 30px;
            text-align: center;
            border-radius: 50px;
            opacity: 0.7;
          }

          .active {
            color: black;
          }

          .currentValue {
            font-size: 1.6rem;
            line-height: 140%;
            padding: 4px 30px;
            border-radius: 50px;
            margin-right: 15px;
            margin-left: 15px;
            background: white;
            min-width: 90px;
            text-align: center;
          }
        }

        .coloryzator {
          padding: 1rem;
        }
      }
    }
  }

  .jumbotron {
    padding-right: 22px;
    padding-left: 22px;
    margin-top: 30px;
    h2 {
      font-weight: 700;
    }

    padding-bottom: 15px;
  }

  .fastNav {
    display: flex;
    align-items: center;

    a {
      margin-right: 15px;
    }

    .supportBtn {
      color: rgba(255, 153, 0, 0.7);
      margin-left: auto;
      font-weight: 600;
      margin-right: 0px;
      &:hover {
        color: #FAC85A;
      }
    }
  }

  .fastFooter {
    p {
      margin-bottom: 0px;
    }
  }
}