.couponCodeForm {
  form {
    button {
      box-shadow: none;
    }
  }

  .cardFooter {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
    .microSpinnerWrapper {
      margin-left: auto;
    }

    button {
      margin-left: 40px;
    }
  }

  .backLink{
    text-align: center;
    margin-top: 5vh;
    
    a{
      margin: auto;
      color: #FAC85A;
      font-weight: 500;
    }
  }
}