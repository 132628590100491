a {
  text-decoration: none;
  color: black;
}

.toast {
  animation-duration: 0.3s !important;
  font-size: 0.9rem !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  min-height: 30px !important;
  color: white !important;
  font-weight: 600 !important;
}

.toasts-container {
  top: 80px !important;
  right: 25px !important;

  .toast {
    font-size: 1.5rem !important;
  }
}

.mainWrapper {
  display: flex;

  .pages {
    min-height: calc(100vh - 70px);
    height: calc(100vh - 70px);
    width: 100%;
    padding-top: 15px;
    overflow-y: scroll;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

// *::-webkit-scrollbar-track {
//   background-color: #ddd;
//   width: 5px;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar {
//   background-color: #ddd;
//   width: 5px;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar-thumb {
//   width: 5px;
//   max-height: 100px;
//   background-color: #ddd;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar-track-piece {
//   background-color: #eee;
//   width: 5px;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }