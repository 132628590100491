.signInForm {
  margin-top: 30px;
  max-width: 480px;
  min-width: 420px;
  padding: 5% 4%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin: auto;

  h5 {
    font-weight: 400;
    font-size: 2.2rem;
    text-align: center;
    padding: 0px 0.75rem;
  }

  p {
    padding: 0px 20% 0px 0.75rem;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0px;

    a {
      font-weight: 500;
      color: #FAC85A;
    }
  }

  .sign-inup-nav {
    padding: 0px 0.75rem;
    font-size: 1.4rem;
    font-weight: 500;

    a {
      color: #FAC85A;
      font-weight: 500;
    }
  }
}

.signinup-page {
  height: 100vh;
  min-height: calc(100vh + 1px);
  background-color: #f7f7f7;
  background-image: url('./../../assets/bg.svg'); //#f9f9f9;
  background-size: cover;
  background-repeat: no-repeat;

  .row {
    height: 100%;

    .col-xl-6,
    .col-xl-7 {
      margin: auto;

      .signInForm {
        // box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
        margin: auto;
      }

      img {
        width: 95%;
      }
    }
  }
}