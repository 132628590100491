$navLandingHeight: 65px;

.navbarLanding {
  height: $navLandingHeight;
  position: fixed;
  width: 100%;
  z-index: 999;

  .navbar {
    z-index: 999;
    height: $navLandingHeight;
    background: rgba(255, 255, 255, 0.8) !important;

    @supports (-moz-appearance:none) {
      background: rgba(255, 255, 255, 1) !important;
    }

    backdrop-filter: blur(15px);
    padding: 0px;
    border-bottom: 1px solid #F3F3F3;

    @media all and (max-width: 992px) {
      height: auto;
    }

    .navbar-collapse {
      @media all and (max-width: 992px) {
        padding-bottom: 25px;
        margin-left: -15px;
        margin-right: -15px;
        padding-right: 15px;

        .actionBtn {
          max-width: 200px;
          margin-left: auto !important;
          margin-right: 7px;
        }

        .actionBtn--dark {
          margin-left: 7px !important;
          margin-right: auto !important;
        }
      }
    }




    .navbar-toggler {
      padding-top: 4px;
      padding-bottom: 4px;
      border-width: 1.5px;
      background: #f3f3f3;

      span {
        width: 22px;
        background: black;
        height: 1.7px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;

        &:before {
          content: '';
          background: black;
          height: 1.5px;
          width: 100%;
          top: -5.5px;
          left: 0px;
          position: absolute;
        }

        &:after {
          content: '';
          background: black;
          height: 1.5px;
          width: 100%;
          top: 5.5px;
          left: 0px;
          position: absolute;
        }
      }
    }

    .container {
      max-width: 1420px;
      padding-left: 30px;
      padding-right: 30px;

      @media all and (max-width: 1921px) {
        max-width: 1280px;
        padding-left: 25px;
        padding-right: 25px;
      }

      @media all and (max-width: 1200px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .brand {
      text-align: center;

      img {
        height: $navLandingHeight;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 140px;
      }
    }

    .midNav {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      height: $navLandingHeight;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      align-self: center;
      justify-items: center;
      justify-self: center;
      padding: 10px;

      span {
        padding: 0px 25px;
        margin: auto 5px;
        font-size: 1.6rem;
        border-radius: 10px;
        height: 100%;
        line-height: calc(#{$navLandingHeight} - 20px);
        cursor: pointer;

        @media all and (max-width: 1921px) {
          font-size: 1.5rem;
        }

        @media all and (max-width: 1921px) {
          padding: 0px 15px;
        }

        &:hover {
          background: #fafafa;
        }

        &:active {
          text-decoration: none;
          background: #f3f3f3;
        }


      }
    }

    .actionNav {
      margin-right: 0px;
      margin-left: auto;
      display: flex;
      width: auto;

      .actionBtn {
        width: 100%;
        margin-left: 12px;

        a {
          padding: 5px 15px;
          border: 1.5px solid black;
          border-radius: 4px;
          font-size: 1.4rem;
          font-weight: 600;
          word-wrap: nowrap;
          display: inline-block;
          white-space: nowrap;
          height: 100%;
          width: 100%;
          color: black;

          @media all and (max-width: 1921px) {
            // font-size: 1.3rem;
          }

          &:active {
            background: #f2f2f2;
            color: #333;
            border: 1.5px solid #333;
          }
        }
      }

      .actionBtn--dark {
        a {
          color: white;
          background: black;

          &:active {
            color: #f2f2f2;
            border: 1.5px solid #333;
            background: #333;
          }
        }
      }
    }
  }
}