.speedo-meter-selector {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  user-select: none !important;

  .logoo {
    user-select: none !important;
    display: inline-block;
    position: relative;
    height: 120px;
    overflow-y: hidden;

    .speedometerr {
      user-select: none !important;
      width: 240px;
      height: 240px;
      border-radius: 100%;
      box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
      display: inline-block;
    }

    .speedometerrr {
      width: 184px;
      user-select: none !important;
      height: 184px;
      margin-top: 28px;
      margin-left: 28px;
      border-radius: 100%;
      border-right: 5px solid white;
      border-bottom: 5px solid #55cc2250;
      border-top: 5px solid #cc552250;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
      display: inline-block;
      transform: rotate(-90deg);
    }

    .needle {
      width: 0px;
      height: 105px;
      background: transparent;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      display: inline-block;
      user-select: none !important;
      left: 120px;
      position: absolute;
      top: 15px;
      transform-origin: bottom;
    }

    .needlee {
      z-index: 99;
      width: 24px;
      height: 24px;
      background: white;
      border-radius: 12px;
      display: inline-block;
      left: -12px;
      position: absolute;
      top: -14px;
      user-select: none !important;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    }
  }
}

@keyframes move {
  0% {
    transform: rotate(-82deg);
  }

  50% {
    transform: rotate(82deg);
  }

  100% {
    transform: rotate(-82deg);
  }
}