.main-section {
  padding: 3rem 15px;
  text-align: center;

  .main-header {
    padding-bottom: 1rem;
    padding-top: 1rem;

    h2 {
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 3.4rem;
    }

    h3 {
      margin-top: 3rem;
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 2.4rem;
    }

    p {
      margin: auto;
      max-width: 900px;
      margin-top: 1rem;
      font-size: 1.6rem;
      line-height: 160%;
    }
  }


  .main-body {
    padding-bottom: 3rem;
    padding-top: 3rem;
    display: flex;
    justify-content: center;
  }
}