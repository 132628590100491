html{
  font-size: 10px;
  font-family: 'Roboto', sans-serif;
  color: black;
  overflow: auto;
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow: hidden;
}

/* @media only screen and (max-width: 1366px) {
  html{
    font-size: 9px;
  }
}

@media only screen and (max-width: 812px) {
  html{
    font-size: 8px;
  }
} */

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}