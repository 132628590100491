.navbarTop {
  height: 70px;

  .navbar {
    z-index: 999;
    height: 70px;
    background: white !important;
    padding: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .liveButton {
      margin-left: 20px;
      padding: 7px 17px;
      border-radius: 2px;
      // border: 1.5px solid black;
      cursor: pointer;
      font-weight: 500;
      font-size: 1.5rem;
      color: #333;
      background: #fafafa;
      user-select: none;

      &:active{
        background: #f3f3f3;
      }
      &:focus{
        background: #f3f3f3;
      }
    }

    .actionBtn {
      margin-left: auto;

      button {
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .actionBtnInsideWrapper {
          align-items: center;
          padding-right: 20px;

          p {
            margin: 0px;
            padding: 0px;
            text-align: left;
            font-size: 1.3rem;
            line-height: 130%;

            &:first-of-type {
              color: #999;
              font-weight: 500;
              font-size: 1.2rem;
            }
          }
        }

        i {
          font-size: 4.2rem;
          color: #FAC85A;
          position: relative;
          // text-shadow: -6px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          // &:after{
          //   content: '';
          //   border: 1.5px solid black;
          //   width: calc(100% + 4px);
          //   height: calc(100% + 4px);
          //   position: absolute;
          //   border-radius: 100px;
          //   left: -5px;
          //   top: -2px;
          // }
        }

        &:after {
          content: none
        }
      }
    }

    .selectEntityWrapper {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: left;

      .fa {
        padding-left: 6px;
        transform: translateY(1px);
      }

      .dropdown {
        .dropdown-toggle {
          box-shadow: none;

          padding-left: 13px;
          padding-right: 13px;

          &:after {
            content: "";
          }

          .dropdownSectionWrapper_item {
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
            max-width: 150px;
            min-width: 150px;
          }
        }
      }

      .dropdown-menu.show {
        left: 50% !important;
        transform: translateX(-50%) !important;
        position: absolute !important;
      }
    }

    .brand {
      width: 100%;
      max-width: 250px;
      border-right: 0.0625rem solid #e9ecef;
      text-align: center;
      min-width: 250px;

      img {
        height: 70px;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 150px;
      }
    }
  }
}

.create-suggestly-entity-modal {
  h5 {
    margin-bottom: 0px;
  }

  .form-group {
    margin-bottom: 0px;
  }
}

.accountMenuDropdown {
  width: 400px;

  .userSectionWrapper {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    text-align: center;

    .userAvatarWrapper {
      font-size: 9rem;
      color: gray;
      line-height: 1.3;
    }

    .userName {
      font-weight: bold;
      margin-bottom: 0px;
    }

  }

  .dropdownSectionWrapper {
    display: flex;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .dropdownSectionWrapper_title {
      width: 50%;
      align-self: center;
    }

    .dropdownSectionWrapper_itemWrapper {
      width: 50%;
      align-self: center;
    }
  }

  .dropdownSection {
    display: flex;
    justify-content: center;

    a {
      width: 50%;
      border-radius: 4px;

      &:hover {
        background: #f7f7f7;
      }
    }

    .dropdownSectionWrapperCol {
      color: black;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .dropdownSectionWrapper_title {
        width: 50%;
        align-self: center;
      }

      .dropdownSectionWrapper_itemWrapper {
        width: 50%;
        align-self: center;
      }
    }
  }
}

.align-center {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;

  &:focus {
    background: none;
  }

  &:hover {
    background: #f7f7f7 !important;
  }

  &:active {
    color: black;
    background: #f7f7f7;
  }

}