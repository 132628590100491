.princing-plans-config {

  .realize-coupon{
    a{
      color: #FAC85A;
      font-weight: 600;
    }
  }

  .switchWithLabels {
    .saveLabel {
      line-height: 110%;
      font-size: 1.2rem;
      padding-left: 1px;
      font-weight: 600;
      color: #FAC85A;
    }
  }

  hr {
    width: 100%;
  }

  .card {
    text-align: center;
    border: none;
    border-radius: 4px;
    overflow: hidden;
  }

  .card-header {
    padding: 0.7rem 1.5rem 0.6rem 1.5rem;
    box-shadow: none;
    border: none;
    font-size: 2rem;
    font-weight: 600;
    padding-top: 30px;
    color: black;

    .most-popuplar {
      position: absolute;
      font-size: 1rem;
      color: #FAC85A;
      top: 0px;
      font-weight: 900;
      right: 13px;
      padding-left: 6px;
      letter-spacing: 0.7px;
      padding-right: 4px;
      border-radius: 2px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .card-body {

    h3 {
      text-align: center;
      font-size: 5.5rem;
      font-weight: 500;
      line-height: 100%;
      color: #FAC85A;
      margin-bottom: 15px;
      position: relative;

      span {
        position: absolute;
        top: 12px;
        margin-left: 3px;
        font-size: 1.6rem;
        color: black;
        font-weight: 500;
      }

    }

    h6 {
      font-weight: 400;
      color: black;
    }

    ul {
      background: white;
      list-style: none;
      list-style-type: none;
      text-align: center;
      padding-inline-start: 0px;

      hr {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
      }

      li {
        padding: 10px 0px;

        i {
          color: #FAC85A;
        }
      }
    }
  }
}