.kalibracja-panel {
  opacity: 0;
  animation: loadDelay 0.3s normal forwards ease-in-out;
  animation-iteration-count: 1;
  padding-bottom: 5vh;

  @keyframes loadDelay {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .lead-item {
    background: white;
    border-radius: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 12px;

    .number {
      border-right: 1px solid rgba(0, 0, 0, 0.5);
      text-align: center;
    }

    .email {
      font-weight: 600;
      padding-left: 35px;
    }

    div {
      text-align: left;
      align-self: center;
    }
  }

}