.speedo-meter {
  position: relative;

  .speedometerTitle {
    text-align: center;
    margin-top: -70px;
    font-size: 2rem;
  }

  .isActive {
    // text-decoration: underline;
    font-weight: 900;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
    // color: rgba(255, 153, 0, 0.7);
  }

  .speedo-meter-dropdown {
    border-radius: 3px;
    box-shadow: none;
    text-align: center;
    position: relative;
    .dropdown-menu {
      height: 200px;
      margin-left: 50%;
      left: 50%;
      box-shadow: none;
      background: transparent;
      pointer-events: none;

        position: absolute !important;
        top: 0 !important;
        transform: translate3d(-50%, 55px, 0px) !important;
        
      .dropdown-menu-insider {
        pointer-events: all;
        height: 200px;
        max-width: 318px;
        margin: auto;
        min-width: 318px;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
        border-top-left-radius: 500px;
        border-top-right-radius: 500px;
        background: white;
      }
    }

    button {
      box-shadow: none;
      background-color: transparent !important;
      ;
      border: none;
      width: 100%;
      padding: 0px;

      &:after {
        display: none;
      }

      &:active {
        background-color: transparent !important;
      }

      &:focus {
        background-color: transparent !important;
      }
    }
  }
}