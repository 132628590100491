.sidenav {
  width: 100%;
  max-width: 250px;
  // box-shadow: 0.5px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition-duration: 0.15s;
  margin-top: -1px;

  li {
    cursor: pointer;
    color: #999;
    user-select: none;
    padding-left: 15px;

    &:hover {
      background: #fdfdfd;
    }
  }

  .active {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.05);
    color: black;
    padding-left: 25px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: #FAC85A;
      right: 10px;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      border: 1px solid #091D3E;
      top: 50%;
      transform: translateY(-50%);
    }


  }
  .backButton{
    margin-top: auto;
  }
}

.sidenav--disabled{
  li {
    cursor: not-allowed;
    color: #ddd;
    user-select: none;
    padding-left: 15px;
    &:hover {
      background: #fdfdfd;
    }
  }
}