.switchWithLabels {
  display: flex;
  justify-content: center;

  .leftLabel {
    width: 160px;
    text-align: right;
    font-size: 1.8rem;
    font-weight: 400;
    margin-top: 0px;
    color: #aaa;
  }

  .midLabel {
    width: 100px;
  }

  .rightLabel {
    width: 160px;
    text-align: left;
    font-size: 1.8rem;
    font-weight: 400;
    margin-top: 0px;
    color: #aaa;
  }

  .active {
    color: black;
  }
}