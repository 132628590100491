.princing-plans-landing {
  background: #f7f7f7;
  padding-top: 13vh;
  padding-bottom: 8vh;

  @media all and (max-width: 1200px) {
    padding-top: 8vh;
    padding-bottom: 4vh;
  }

  .sectionTitle {
    text-align: center;

    h4 {
      font-weight: bold;
      font-size: 3.2rem;
      line-height: 130%;

      @media all and (max-width: 1921px) {
        font-size: 2.8rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 2.4rem;
      }

      @media all and (max-width: 576px) {
        font-size: 2rem;
      }
    }

    p {
      font-weight: 300;
      font-size: 2rem;
      line-height: 150%;

      @media all and (max-width: 1921px) {
        font-size: 1.8rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }

      @media all and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
  }

  .switchWithLabels {
    padding-top: 50px;
    padding-bottom: 50px;

    @media all and (max-width: 1921px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @media all and (max-width: 1200px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .midLabel {
      text-align: center;
    }

    .saveLabel {
      line-height: 110%;
      font-size: 1.1rem;
      padding-left: 1px;
      font-weight: 700;
      color: #FAC85A;

      @media all and (max-width: 1200px) {
        font-size: 1rem;
      }

      @media all and (max-width: 576px) {
        font-size: 0.9rem;
      }
    }


    .rightLabel,
    .leftLabel {
      @media all and (max-width: 1200px) {
        font-size: 1.5rem;
      }

      @media all and (max-width: 576px) {
        font-size: 1.4rem;
      }
    }

  }

  hr {
    width: 100%;
  }

  .card {
    text-align: center;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 20px;

    @media all and (max-width: 1200px) {
      padding-bottom: 10px;
      padding-top: 30px;
    }
  }

  .card-header {
    padding: 0.7rem 1.5rem 0.6rem 1.5rem;
    box-shadow: none;
    border: none;
    font-size: 2rem;
    font-weight: 600;
    padding-top: 30px;
    color: black;

    @media all and (max-width: 1921px) {
      padding: 0.7rem 1.3rem 0.6rem 1.3rem;
      font-size: 1.8rem;
    }

    @media all and (max-width: 1200px) {
      padding: 0px;
      font-size: 1.7rem;
    }

    .most-popuplar {
      position: absolute;
      font-size: 1rem;
      color: #FAC85A;
      top: 0px;
      font-weight: 900;
      right: 13px;
      padding-left: 6px;
      letter-spacing: 0.7px;
      padding-right: 4px;
      border-radius: 2px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .card-body {

    .price {
      font-weight: 400;
      font-size: 6.5rem;
      line-height: 1.5;
      color: #FAC85A;
      padding-top: 10px;
      padding-bottom: 40px;

      @media all and (max-width: 1921px) {
        padding-top: 0px;
        font-size: 5.5rem;
        padding-bottom: 20px;
      }

      @media all and (max-width: 1200px) {
        font-size: 5rem;
        padding-bottom: 10px;
        margin-top: -5px;
      }

      @media all and (max-width: 576px) {
        font-size: 4.5rem;
      }

      span {
        margin-left: 5px;
        font-weight: 400;
        font-size: 2.8rem;
        line-height: 1.5;
        color: black;

        @media all and (max-width: 1921px) {
          font-size: 2.4rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 2rem;
        }

        @media all and (max-width: 576px) {
          font-size: 1.6rem;
        }

      }
    }

    h6 {
      font-weight: 400;
      color: black;

      @media all and (max-width: 1200px) {
        font-size: 1.5rem;
      }
    }

    ul {
      background: white;
      list-style: none;
      list-style-type: none;
      text-align: center;
      padding-inline-start: 0px;

      hr {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
      }

      li {
        padding: 10px 0px;

        @media all and (max-width: 1200px) {
          padding: 5px 0px;
          font-size: 1.5rem;
        }

        i {
          color: #FAC85A;
        }
      }
    }
  }
}