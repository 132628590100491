.active-plans {
  padding-right: 15px;
  padding-left: 15px;

  .active-plan-item {
    background: white;
    border-radius: 4px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-top: 12px;

    .roleName {
      font-weight: bold;
    }

    .endDate {
      text-align: right;
      font-weight: bold;
    }
  }
}