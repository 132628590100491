.domains-config-form {

  hr {
    margin-bottom: 3rem;
    width: 100%;
  }

  &:first-of-type {
    .card {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    .card {
      margin-bottom: 0px;
    }
  }
}