.product-item {
  border-radius: 4px;



  &:first-of-type {
    .card {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    .card {
      margin-bottom: 0px;
    }
  }
}

.scenario_nav_wrapper {
  display: flex;
  margin-top: 10px;

  .back {
    margin-right: auto;

    i {
      &:before {
        margin-right: 2px;
      }
    }
  }

  .cancel {
    margin-left: auto;
  }
}


.scenarioHeader {
  text-align: center;
  padding-top: 0rem;
  padding-bottom: 2rem;
}

.scenario_options {
  z-index: 0;
  position: fixed;
  bottom: 0%;
  z-index: 999;
  background: white;
  height: calc(100vh - 70px);
  transform: translateX(-15px);
  border-radius: 4px;
  overflow-y: auto;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);

  button{
    box-shadow: none;
  }
}

.outputTypeScenario {
  padding: 3rem 6rem;
  border: 2px solid #f7f7f7;
  border-radius: 6px;
  margin: 20px 10px !important;

  cursor: pointer;

  &:hover {
    border-radius: 4px;
    border: 2px solid #FAC85A;
    // background: lighten(#FAC85A, 10);
  }

  h5 {
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 1rem;

  }

  .scenarioImg {
    width: 90%;
  }

  .scenarioOptions {
    .scenarioOption {
      width: auto;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 4px;
      background: #f7f7f7;
      font-weight: bold;
    }

    .scenarioIcon {
      width: auto;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      align-items: center;
      display: flex;
      justify-content: center;

      .fa-plus {
        color: #999;
      }

      .fa-arrow-right {
        color: #FAC85A;
      }
    }

    .scenarioIconVertical {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
      color: #999;
    }

    .colorful {
      background: lighten(#FAC85A, 30);
    }
  }
}

.outputTypeScenario.active {
  border: 2px solid lighten(#FAC85A, 0);
}

.outPutDescriptionInside {
  .outputTypeScenario {
    cursor: default !important;
  }
}